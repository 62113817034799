// The following code is exported from @reach/rect@v0.18.0 and refactored
import { RefObject, useEffect, useLayoutEffect, useRef, useState } from 'react'

interface RectState {
  rect: DOMRect | undefined
  hasRectChanged: boolean
  callbacks: Function[]
}

interface PartialRect extends Partial<DOMRect> {
  readonly bottom: number
  readonly height: number
  readonly left: number
  readonly right: number
  readonly top: number
  readonly width: number
}

/**
 * @see Docs https://reach.tech/rect#userect
 */
interface UseRectOptions {
  /**
   * Tells `Rect` to observe the position of the node or not. While observing,
   * the `children` render prop may call back very quickly (especially while
   * scrolling) so it can be important for performance to avoid observing when
   * you don't need to.
   *
   * This is typically used for elements that pop over other elements (like a
   * dropdown menu), so you don't need to observe all the time, only when the
   * popup is active.
   *
   * Pass `true` to observe, `false` to ignore.
   *
   * @see Docs https://reach.tech/rect#userect-observe
   */
  observe?: boolean
  /**
   * Calls back whenever the `rect` of the element changes.
   *
   * @see Docs https://reach.tech/rect#userect-onchange
   */
  onChange?: (rect: PartialRect) => void
}

const canUseDOM = () =>
  !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  )

/**
 * React currently throws a warning when using useLayoutEffect on the server. To
 * get around it, we can conditionally useEffect on the server (no-op) and
 * useLayoutEffect in the browser. We occasionally need useLayoutEffect to
 * ensure we don't get a render flash for certain operations, but we may also
 * need affected components to render on the server. One example is when setting
 * a component's descendants to retrieve their index values.
 *
 * Important to note that using this hook as an escape hatch will break the
 * eslint dependency warnings unless you rename the import to `useLayoutEffect`.
 * Use sparingly only when the effect won't effect the rendered HTML to avoid
 * any server/client mismatch.
 *
 * If a useLayoutEffect is needed and the result would create a mismatch, it's
 * likely that the component in question shouldn't be rendered on the server at
 * all, so a better approach would be to lazily render those in a parent
 * component after client-side hydration.
 *
 * https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
 * https://github.com/reduxjs/react-redux/blob/master/src/utils/useIsomorphicLayoutEffect.js
 *
 * @param effect
 * @param deps
 */
const useIsomorphicLayoutEffect = canUseDOM() ? useLayoutEffect : useEffect

let useRectObservedNodes = new Map<Element, RectState>()
let useRectRafId: number
const useRectCompareProps: (keyof DOMRect)[] = [
  'bottom',
  'height',
  'left',
  'right',
  'top',
  'width',
]

const observeChanges = () => {
  const changedStates: RectState[] = []

  useRectObservedNodes.forEach((state, node) => {
    const newRect = node.getBoundingClientRect()

    if (
      useRectCompareProps.some((prop) => newRect[prop] !== state.rect?.[prop])
    ) {
      state.rect = newRect
      changedStates.push(state)
    }
  })

  changedStates.forEach((state) => {
    state.callbacks.forEach((cb) => cb(state.rect))
  })

  useRectRafId = window.requestAnimationFrame(observeChanges)
}

/**
 * Measures DOM elements (aka. bounding client rect).
 *
 * @see getBoundingClientRect https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
 * @see Docs                  https://reach.tech/rect
 * @see Source                https://github.com/reach/reach-ui/tree/main/packages/rect
 * @param nodeRef
 * @param observeOrOptions
 */
export function useRect<T extends Element = HTMLElement>(
  nodeRef: RefObject<T | undefined | null>,
  observeOrOptions?: UseRectOptions
): null | DOMRect {
  let observe: boolean = observeOrOptions?.observe ?? true
  let onChange: UseRectOptions['onChange'] = observeOrOptions?.onChange

  let [element, setElement] = useState(nodeRef.current)
  let initialRectIsSet = useRef(false)
  let initialRefIsSet = useRef(false)
  let [rect, setRect] = useState<DOMRect | null>(null)
  let onChangeRef = useRef(onChange)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useIsomorphicLayoutEffect(() => {
    onChangeRef.current = onChange
    if (nodeRef.current !== element) {
      setElement(nodeRef.current)
    }
  })

  useIsomorphicLayoutEffect(() => {
    if (element && !initialRectIsSet.current) {
      initialRectIsSet.current = true
      setRect(element.getBoundingClientRect())
    }
  }, [element])

  useIsomorphicLayoutEffect(() => {
    if (!observe) {
      return
    }

    let elem = element
    // State initializes before refs are placed, meaning the element state will
    // be undefined on the first render. We still want the rect on the first
    // render, so initially we'll use the nodeRef that was passed instead of
    // state for our measurements.
    if (!initialRefIsSet.current) {
      initialRefIsSet.current = true
      elem = nodeRef.current
    }

    if (!elem) {
      if (process.env.NODE_ENV !== 'production') {
        console.warn('You need to place the ref')
      }
      return
    }

    const cb = (rect: DOMRect) => {
      onChangeRef.current?.(rect)
      setRect(rect)
    }
    let wasEmpty = useRectObservedNodes.size === 0

    if (useRectObservedNodes.has(elem)) {
      useRectObservedNodes.get(elem)!.callbacks.push(cb)
    }

    if (!useRectObservedNodes.has(elem)) {
      useRectObservedNodes.set(elem, {
        rect: undefined,
        hasRectChanged: false,
        callbacks: [cb],
      })
    }

    if (wasEmpty) {
      observeChanges()
    }

    return () => {
      if (!elem) {
        return
      }

      let state = useRectObservedNodes.get(elem)

      if (state) {
        // Remove the callback
        const index = state.callbacks.indexOf(cb)

        if (index >= 0) {
          state.callbacks.splice(index, 1)
        }

        // Remove the node reference
        if (!state.callbacks.length) {
          useRectObservedNodes.delete(elem)
        }

        // Stop the loop
        if (!useRectObservedNodes.size) {
          cancelAnimationFrame(useRectRafId)
        }
      }
    }
  }, [observe, element, nodeRef])

  return rect
}
