import { useCallback, useContext } from 'react'
import cx from 'classnames'

import {
  SanityProductFragment,
  SanityProductOption,
  SanityProductOptionSetting,
} from '@data/sanity/queries/types/product'
import { hasObject } from '@lib/helpers'
import { ProductVariantPreviewContext } from '@lib/product-variant-preview-context'
import {
  PartialProductVariantOption,
  ProductVariantSelectionContext,
} from '@lib/product-variant-selection-context'
import { StringsContext } from '@lib/strings'

import {
  getButtonStyles,
  ButtonColor,
  ButtonSize,
  ButtonVariant,
} from '@components/buttons/button'
import RadioItem from '@components/radio-item'
import Swatch from '@components/swatch'

interface ProductOptionValueProps {
  value: string
  product: SanityProductFragment
  option: SanityProductOption
  optionSettings: SanityProductOptionSetting[]
  strictMatch?: boolean
}

const ProductOptionValue = ({
  value,
  product,
  option,
  optionSettings,
  strictMatch,
}: ProductOptionValueProps) => {
  const strings = useContext(StringsContext)
  const { setPreviewVariantId } = useContext(ProductVariantPreviewContext)
  const { selectedOptions } = useContext(ProductVariantSelectionContext)

  const optionColor = optionSettings.find(({ forOption }) => {
    const optionParts = forOption?.split(':')

    return optionParts?.[0] === option.name && optionParts?.[1] === value
  })?.color

  const handleMouseEnter = useCallback(() => {
    const previewVariantOptions: PartialProductVariantOption[] = [
      ...(selectedOptions?.filter(
        (selectedOption) => selectedOption.name !== option.name
      ) ?? []),
      {
        name: option.name,
        value,
      },
    ]

    // Find variant that matches preview options
    const newVariant = product.variants?.find(({ options }) =>
      previewVariantOptions.every((previewVariantOption) =>
        hasObject(options, previewVariantOption)
      )
    )

    if (newVariant) {
      setPreviewVariantId(newVariant.id)
    }
  }, [product, option.name, value, setPreviewVariantId, selectedOptions])
  const handleMouseLeave = useCallback(() => {
    setPreviewVariantId(null)
  }, [setPreviewVariantId])

  // With strict matching, match all options, otherwise match just this option
  const currentOption: PartialProductVariantOption = {
    name: option.name,
    value,
  }
  const potentialOptions: PartialProductVariantOption[] = [
    ...(selectedOptions?.filter(
      (selectedOption) => selectedOption.name !== option.name
    ) ?? []),
    currentOption,
  ]
  const potentialVariant = product.variants?.find((variant) =>
    variant.options.every((variantOption) =>
      hasObject(potentialOptions, variantOption)
    )
  )
  const isInStock = strictMatch
    ? !!potentialVariant?.inStock
    : !!product.variants?.some(
        (variant) =>
          !!variant.inStock &&
          variant.options.some((variantOption) =>
            hasObject([currentOption], variantOption)
          )
      )

  const isActive = !!selectedOptions?.some(
    (selectedOption) =>
      selectedOption.name === option.name && selectedOption.value === value
  )

  const isDisabled = !isInStock && !!potentialVariant && !isActive

  return (
    <RadioItem
      value={value}
      className={cx('mt-1 mb-1 ml-2', {
        'p-0 bg-transparent': !!optionColor,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {optionColor && (
        <Swatch
          label={strings.productColorOptionLabel
            .replace(/{value}/gi, value)
            .replace(/{name}/gi, option.name.toLowerCase())}
          color={optionColor}
          isActive={isActive}
          isCrossed={isDisabled}
        />
      )}

      {!optionColor && (
        <div
          className={cx(
            getButtonStyles({
              variant: ButtonVariant.SECONDARY,
              color: ButtonColor.BLACK,
              size: ButtonSize.SMALL,
              isActive: isActive,
            }),
            {
              'opacity-30': isDisabled,
            }
          )}
        >
          {value}
        </div>
      )}
    </RadioItem>
  )
}

export default ProductOptionValue
