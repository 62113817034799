import cx from 'classnames'

import {
  SanityProductFragment,
  SanityProductOption,
  SanityProductOptionSetting,
} from '@data/sanity/queries/types/product'

import RadioGroup from '@components/radio-group'
import ProductOptionValue from './product-option-value'

interface ProductOptionProps {
  value: string
  onChange: (value: string) => void
  label?: string
  option: SanityProductOption
  optionSettings: SanityProductOptionSetting[]
  product: SanityProductFragment
  strictMatch: boolean
  hideLabels?: boolean
  inProductCard?: boolean
}

const ProductOption = ({
  value,
  onChange,
  label,
  option,
  optionSettings,
  product,
  inProductCard,
  strictMatch,
  hideLabels = false,
}: ProductOptionProps) => {
  return (
    <div
      className={cx('flex', {
        'mt-2 py-2 items-center': !inProductCard,
        'm-0 p-0 border-0': inProductCard,
      })}
    >
      {!hideLabels && (
        <div className="mr-12 text-base font-semibold uppercase tracking-wider">
          {label || option.name}
        </div>
      )}

      <RadioGroup
        value={value}
        onChange={onChange}
        className={cx('flex flex-wrap -mt-1', {
          'justify-start': inProductCard,
          'ml-auto justify-end': !inProductCard,
        })}
      >
        {option.values.map((value) => (
          <ProductOptionValue
            key={value}
            value={value}
            product={product}
            option={option}
            optionSettings={optionSettings}
            strictMatch={strictMatch}
          />
        ))}
      </RadioGroup>
    </div>
  )
}

export default ProductOption
