import { AnimatePresence, motion } from 'framer-motion'
import { useMemo } from 'react'

import {
  SanityProductGalleryPhoto,
  SanityProductVariantFragment,
} from '@data/sanity/queries/types/product'
import { fadeAnimation } from '@lib/animate'
import { getProductGalleryPhotos } from '@lib/product'

import Photo from '@components/photo'
import PhotoCarousel from '@components/photo-carousel'

interface ProductGalleryProps {
  photosets: SanityProductGalleryPhoto[]
  variant?: SanityProductVariantFragment
  previewVariant?: SanityProductVariantFragment
  hasArrows?: boolean
  hasDots?: boolean
  hasDrag?: boolean
  hasCounter?: boolean
  preloadFirstPhoto?: boolean
}

const ProductGallery = ({
  photosets,
  variant,
  previewVariant,
  hasArrows = false,
  hasDots = false,
  hasDrag = false,
  hasCounter = false,
  preloadFirstPhoto = false,
}: ProductGalleryProps) => {
  const photos = useMemo(() => {
    const previewVariantPhotos = previewVariant
      ? getProductGalleryPhotos(photosets, previewVariant)
      : []
    const variantPhotos = variant
      ? getProductGalleryPhotos(photosets, variant)
      : []
    return previewVariantPhotos.length > 0
      ? previewVariantPhotos
      : variantPhotos
  }, [photosets, variant, previewVariant])

  // Update ID to trigger transition animation
  const photosetId = photos.map(({ id }) => id).join('')

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={photosetId}
        initial="hide"
        animate="show"
        exit="hide"
        variants={fadeAnimation}
      >
        <PhotoCarousel
          id="product-gallery"
          slideCount={photos.length}
          hasArrows={hasArrows}
          hasDots={hasDots}
          hasCounter={hasCounter}
          hasDrag={hasDrag}
          cornerControls={true}
        >
          {photos.map((photo, index) => (
            <div
              key={photo.id}
              className="carousel-slide relative flex flex-grow-0 flex-shrink-0 w-full min-h-full overflow-hidden justify-center"
            >
              <Photo
                image={photo}
                priority={preloadFirstPhoto && index === 0}
              />
            </div>
          ))}
        </PhotoCarousel>
      </motion.div>
    </AnimatePresence>
  )
}

export default ProductGallery
