import { useContext } from 'react'

import { LanguageContext, Locale, localeNames } from '@lib/language'
import { ShopContext } from '@lib/shop'
import { StringsContext } from '@lib/strings'

import Icon from './icon'

interface LanguageSwitchProps {
  id: string
  buttonClassName?: string
  hasCurrency?: boolean
}

const LanguageSwitch = ({
  id,
  buttonClassName,
  hasCurrency = false,
}: LanguageSwitchProps) => {
  const strings = useContext(StringsContext)
  const { currencyCode } = useContext(ShopContext)
  const { locale, publicLocales, changeLanguage } = useContext(LanguageContext)

  return (
    <div className="relative flex align-center">
      <select
        className="absolute block top-0 left-0 w-full h-full opacity-0 z-10 cursor-pointer focus:outline-none"
        defaultValue={locale}
        onChange={({ target }) => changeLanguage(target.value as Locale)}
        onBlur={({ target }) => changeLanguage(target.value as Locale)}
        aria-label={strings.languageSwitch}
      >
        {publicLocales.map((publicLocale) => (
          <option key={publicLocale} value={publicLocale}>
            {localeNames[publicLocale]}
          </option>
        ))}
      </select>

      <button className={buttonClassName}>
        <span className="flex items-center pointer-events-none">
          {localeNames[locale]} {hasCurrency && `· ${currencyCode}`}
          <span className="inline-block ml-2">
            <Icon
              id={`language-switch-${id}`}
              name="ChevronDown"
              className="block h-[1rem]"
              color="text-current"
            />
          </span>
        </span>
      </button>
    </div>
  )
}

export default LanguageSwitch
