import { useContext, useEffect } from 'react'
import cx from 'classnames'

import { SanityVideoType } from '@data/sanity/queries/types/video'
import { SiteContext } from '@lib/site'

import Icon from '@components/icon'
import MuxVideo from '@components/video/mux-video'
import VimeoVideo from '@components/video/vimeo-video'

const VideoModal = () => {
  const {
    videoModal: { isOpen, video },
    toggleVideoModal,
  } = useContext(SiteContext)

  useEffect(() => {
    document.body.classList.toggle('max-h-screen', isOpen)
    document.body.classList.toggle('overflow-hidden', isOpen)
  }, [isOpen])

  const close = () => toggleVideoModal(false)

  return (
    <div
      className={cx(
        'bg-[rgba(0,0,0,0.95)] fixed inset-0 w-full h-full py-10 overflow-y-scroll no-scrollbar z-90 flex items-center justify-center flex-wrap',
        {
          hidden: !isOpen,
          block: isOpen,
        }
      )}
    >
      <button
        onClick={close}
        className="fixed top-4 right-4 rounded-full bg-black w-10 h-10 md:w-14 md:h-14 flex items-center justify-center z-10"
      >
        <Icon
          id="modal-close"
          name="Cross"
          className="text-gray h-4 w-4 md:h-5 md:w-5"
        />
      </button>

      <div className="container w-full">
        {video?.vimeoVideo && video.type === SanityVideoType.VIMEO && (
          <VimeoVideo autoplay={isOpen} video={video.vimeoVideo} />
        )}

        {video?.muxVideo && video.type === SanityVideoType.MUX && (
          <MuxVideo
            video={video.muxVideo}
            showControls={video.settings?.controls}
            customControls={video.settings?.customControls}
            autoplay={video.settings?.autoplay}
            loop={video.settings?.loop}
            muted={video.settings?.muted}
          />
        )}
      </div>
    </div>
  )
}

export default VideoModal
