import { SanityProductFragment } from '@data/sanity/queries/types/product'

import ProductCard from '@blocks/shop/product-card'

interface FeaturedProductsProps {
  products: SanityProductFragment[]
  onClick?: () => void
}

const FeaturedProducts = ({ products, onClick }: FeaturedProductsProps) => {
  if (products.length === 0) {
    return null
  }

  return (
    <>
      {products.map((product) => (
        <ProductCard
          key={product.id}
          product={product}
          hasVisuals={!!product.photos.main || !!product.photos.listing}
          showThumbnails={!!product.photos.listing}
          onClick={onClick}
          isFeatured
        />
      ))}
    </>
  )
}

export default FeaturedProducts
