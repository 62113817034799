import cx from 'classnames'
import dynamic from 'next/dynamic'
import { useRef } from 'react'

import {
  SanityMuxVideo,
  SanityVideoAspectRatioValue as AspectRatioValue,
} from '@data/sanity/queries/types/video'
import { useIntersectionObserver } from '@lib/dom-interaction-observer'
import {
  aspectRatioPadding,
  getMuxVideoAspectRatio,
  MuxPlayerProps,
} from '@lib/video'

const MuxPlayer = dynamic(() => import('./mux-player'))

type MuxVideoProps = Omit<MuxPlayerProps, 'assetDocument'> & {
  video: SanityMuxVideo
  customAspectRatio?: {
    desktop: AspectRatioValue
    mobile: AspectRatioValue
  } | null
}

const MuxVideo = ({
  video,
  autoplay,
  showControls,
  customControls,
  muted,
  loop,
  customAspectRatio,
}: MuxVideoProps) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const isInView = useIntersectionObserver({
    ref: containerRef,
    options: {
      triggerOnce: true,
      threshold: 0.1,
    },
  })

  const videoAspectRatio = getMuxVideoAspectRatio(video)

  return (
    <div
      ref={containerRef}
      className={cx(
        'relative w-full z-0 h-full',
        customAspectRatio
          ? `${aspectRatioPadding[customAspectRatio.mobile]} sm:${
              aspectRatioPadding[customAspectRatio.desktop]
            }`
          : ''
      )}
      style={{
        ...(customAspectRatio
          ? {}
          : { paddingTop: `${100 / videoAspectRatio}%` }),
      }}
    >
      {isInView && (
        <MuxPlayer
          assetDocument={video.asset}
          showControls={showControls}
          customControls={customControls}
          autoplay={autoplay}
          muted={muted}
          loop={loop}
          playsInline={autoplay && !showControls}
          videoStyle={{
            objectFit: 'cover',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            height: '100%',
            width: '100%',
          }}
        />
      )}
    </div>
  )
}

export default MuxVideo
