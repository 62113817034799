import { SanityProductFragment } from '@data/sanity/queries/types/product'
import { hasObject } from './helpers'
import { Filter, FilterValue } from './product'

interface OptionValue {
  name: string
  value: string
}

/**
 * Gets default product option taking into account filters.
 */
const getDefaultOptionsWithFilters = (
  product: SanityProductFragment,
  activeFilterValues: FilterValue[]
): OptionValue[] => {
  return activeFilterValues
    .map((filter) => {
      const currentFilter = product.filters?.find(
        ({ forOption, slug }) => slug === filter.value && forOption
      )

      if (!currentFilter) {
        return null
      }

      const option = currentFilter.forOption.split(':')
      const optionValue: OptionValue = { name: option[0], value: option[1] }

      return optionValue
    })
    .filter(Boolean) as OptionValue[]
}

/**
 * Gets the default product variant based on option values.
 */
const getDefaultProductVariant = (
  product: SanityProductFragment,
  optionValues: OptionValue[]
) => {
  const lastOptionValue =
    optionValues.length > 0 ? optionValues[optionValues.length - 1] : null

  const firstOption = product.options?.[0]
  const firstOptionValue = firstOption
    ? ({
        name: firstOption?.name,
        value: firstOption?.values?.[0],
      } as OptionValue)
    : null

  const optionValue = lastOptionValue ?? firstOptionValue

  if (!optionValue) {
    return null
  }

  return (
    product.variants?.find(({ options }) => hasObject(options, optionValue)) ??
    null
  )
}

/**
 * Gets filter values from filters.
 */
const getFilterValues = (filters: Filter[]): FilterValue[] => {
  return (
    filters.flatMap(({ name, values }) =>
      values.map((value) => ({ name, value }))
    ) ?? []
  )
}

/**
 * Gets the default or first product variant based on current active filters.
 */
export const getProductVariant = (
  product: SanityProductFragment,
  activeFilters: Filter[]
) => {
  const activeFilterValues = getFilterValues(activeFilters)
  const defaultOptions = getDefaultOptionsWithFilters(
    product,
    activeFilterValues
  )
  const defaultVariant = getDefaultProductVariant(product, defaultOptions)
  const firstVariant = product.variants?.[0] ?? null

  return defaultVariant ?? firstVariant
}
