import { useContext, useState, useRef } from 'react'
import { usePopper } from 'react-popper'
import { Placement } from '@popperjs/core'
import { useClickOutside } from '@react-hooks-library/core'
import cx from 'classnames'

import { StringsContext } from '@lib/strings'
import { ShopContext } from '@lib/shop'

import Icon from '@components/icon'

interface PrintShopMenuProps {
  className?: string
  placement?: Placement
}

const PrintShopMenu = ({
  className,
  placement = 'bottom-start',
}: PrintShopMenuProps) => {
  const strings = useContext(StringsContext)
  const { printShop, setPrintShop } = useContext(ShopContext)

  const ref = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const [popperReferenceElement, setPopperReferenceElement] = useState(null)
  const [popperElement, setPopperElement] = useState(null)
  const { styles, attributes } = usePopper(
    popperReferenceElement,
    popperElement,
    { placement }
  )

  useClickOutside(ref, () => {
    setIsOpen(false)
  })

  if (!printShop) {
    return null
  }

  return (
    <div ref={ref} className={className}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="flex items-center text-left gap-3"
        // @ts-expect-error https://popper.js.org/react-popper/v2/
        ref={setPopperReferenceElement}
      >
        <Icon
          id="selected-print-shop-store-icon"
          className="text-2xl"
          name="Store"
        />

        <div>
          <p className="text-sm font-medium leading-4">{printShop.name}</p>
          <p className="text-xs italic leading-4">{printShop.school.name}</p>
        </div>

        <Icon id="selected-print-shop-dropdown-icon" name="ChevronDown" />
      </button>

      <div
        // @ts-expect-error https://popper.js.org/react-popper/v2/
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {isOpen && (
          <div className="my-3 p-3 bg-white text-black shadow">
            <button
              className="inline-flex items-center font-medium gap-3 text-sm w-full"
              onClick={() => {
                setPrintShop(null)
                setIsOpen(false)
              }}
            >
              <Icon
                id="selected-print-shop-logout-icon"
                name="Logout"
                className="text-md"
              />

              <span>{strings.headerShopMenuLogoutLabel}</span>
            </button>
          </div>
        )}
      </div>
    </div>
  )
}

export default PrintShopMenu
