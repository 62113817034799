import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useState,
} from 'react'

import { SanityProductFragment } from '@data/sanity/queries/types/product'

interface ProductVariantPreview {
  previewVariantId: number | null
  setPreviewVariantId: Dispatch<SetStateAction<number | null>>
}

interface ProductVariantPreviewContextProviderProps {
  product: SanityProductFragment
  children: ReactNode
}

export const ProductVariantPreviewContext =
  createContext<ProductVariantPreview>({
    previewVariantId: null,
    setPreviewVariantId: () => null,
  })

export const ProductVariantPreviewContextProvider = ({
  product,
  children,
}: ProductVariantPreviewContextProviderProps) => {
  const [previewVariantId, setPreviewVariantId] = useState<number | null>(null)

  return (
    <ProductVariantPreviewContext.Provider
      value={{
        previewVariantId,
        setPreviewVariantId,
      }}
    >
      {children}
    </ProductVariantPreviewContext.Provider>
  )
}
