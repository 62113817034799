import { useContext } from 'react'
import cx from 'classnames'

import { SiteContext } from '@lib/site'

import Icon from '@components/icon'

interface MegaNavButtonProps {
  id: string
  title: string
  className?: string
}

const MegaNavButton = ({ id, title, className }: MegaNavButtonProps) => {
  const { megaNavigation, toggleMegaNavigation } = useContext(SiteContext)

  const isActive = megaNavigation?.activeId === id

  return (
    <button
      aria-expanded={isActive}
      aria-controls={`meganav-${id}`}
      onClick={() => toggleMegaNavigation(!isActive ? true : 'toggle', id)}
      className={cx('inline-flex items-center', className)}
    >
      {title}
      <Icon
        name={isActive ? 'ChevronUp' : 'ChevronDown'}
        id={`meganav-${id}-button`}
        className="mt-1 ml-1"
      />
    </button>
  )
}

export default MegaNavButton
