import {
  BaseSyntheticEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from 'react'
import axios, { AxiosResponse } from 'axios'
import { useForm } from 'react-hook-form'
import { motion, AnimatePresence } from 'framer-motion'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import { SanityNewsletter } from '@data/sanity/queries/types/blocks'
import { triggerNewsletterSignUpEvent } from '@lib/analytics'
import { fadeAnimation } from '@lib/animate'
import { LanguageContext } from '@lib/language'
import { serializers } from '@lib/serializers'
import { StringsContext } from '@lib/strings'
import { NewsletterResponse } from '@pages/api/klaviyo/newsletter-join'

import Alert from '@components/alert'
import Button, { ButtonVariant, ButtonColor } from '@components/buttons/button'
import Checkbox from '@components/checkbox'
import InputField from '@components/input-field'

interface NewsletterProps extends SanityNewsletter {
  id: string
  className?: string
  isInvertedColors?: boolean
}

interface NewsletterFormValues {
  fullname: string
  email: string
  acceptTerms: boolean
}

const Newsletter = ({
  id,
  klaviyoListID,
  terms,
  submit,
  successMsg,
  errorMsg,
  className,
  isInvertedColors,
}: NewsletterProps) => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [isError, setIsError] = useState(false)
  const { handleSubmit, register, watch, reset } =
    useForm<NewsletterFormValues>()
  const strings = useContext(StringsContext)
  const { locale } = useContext(LanguageContext)

  // Wait for successful submit to send event
  useEffect(() => {
    if (isSuccess) {
      triggerNewsletterSignUpEvent()
    }
  }, [isSuccess])

  const hasAgreed = watch('acceptTerms')

  // Call to reset the form
  const resetForm = (e: FormEvent) => {
    e.preventDefault()
    reset()
    setIsError(false)
    setIsSuccess(false)
    setIsSubmitting(false)
  }

  const addToNewsletter = async (
    newsletterFormValues: NewsletterFormValues
  ) => {
    const payload = JSON.stringify({
      listID: klaviyoListID,
      ...newsletterFormValues,
    })

    try {
      await axios.post<
        NewsletterResponse,
        AxiosResponse<NewsletterResponse>,
        string
      >('/api/klaviyo/newsletter-join', payload, {
        headers: {
          'Content-Type': 'application/json',
          'X-Locale': locale,
        },
      })

      setIsSubmitting(false)
      setIsSuccess(true)
    } catch (error) {
      setIsSubmitting(false)
      setIsError(true)
      console.log(error)
    }
  }

  // handle form submission
  const onSubmit = (
    newsletterFormValues: NewsletterFormValues,
    event?: BaseSyntheticEvent
  ) => {
    event?.preventDefault()

    // Set an error if there is no Klaviyo list supplied
    if (!klaviyoListID) {
      setIsError(true)
      return
    }

    // Stop if accepting of terms ir required
    if (!hasAgreed && terms) {
      return
    }

    setIsSubmitting(true)
    setIsError(false)
    addToNewsletter(newsletterFormValues)
  }

  const fullnameRegister = register('fullname')
  const emailRegister = register('email', {
    required: strings.emailMissing,
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: strings.emailInvalid,
    },
  })
  const acceptTermsRegister = register('acceptTerms')

  return (
    <form className={cx(className)} onSubmit={handleSubmit(onSubmit)}>
      <AnimatePresence mode="wait">
        {!isError && !isSuccess && (
          <motion.div
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <input
              type="text"
              autoComplete="off"
              className="hidden"
              aria-hidden="true"
              onChange={fullnameRegister.onChange}
              onBlur={fullnameRegister.onBlur}
              ref={fullnameRegister.ref}
              name={fullnameRegister.name}
            />

            <InputField
              id={`newsletter-${id}-email`}
              type="email"
              formRegister={emailRegister}
              placeholder={strings.emailAddress}
              borderBottom
              className="mb-4"
              isInvertedColors={isInvertedColors}
            ></InputField>

            {terms && (
              <Checkbox
                id={`newsletter-${id}-acceptTerms`}
                formRegister={acceptTermsRegister}
                className="mb-10"
                isInvertedColors={isInvertedColors}
              >
                <BlockContent
                  renderContainerOnSingleChild
                  className={cx('rc rc-checkbox')}
                  blocks={terms}
                  serializers={serializers}
                />
              </Checkbox>
            )}

            <Button
              variant={ButtonVariant.PRIMARY}
              icon="ArrowRight"
              color={ButtonColor.YELLOW}
              type="submit"
              disabled={isSubmitting || (terms && !hasAgreed)}
            >
              {submit}
            </Button>
          </motion.div>
        )}

        {isSuccess && (
          <motion.div
            key="success"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert>
              <BlockContent
                renderContainerOnSingleChild
                className="rc"
                blocks={successMsg}
                serializers={serializers}
              />
            </Alert>
          </motion.div>
        )}

        {isError && (
          <motion.div
            key="error"
            initial="hide"
            animate="show"
            exit="hide"
            variants={fadeAnimation}
          >
            <Alert buttonText={strings.buttonTryAgain} onClick={resetForm}>
              <BlockContent
                renderContainerOnSingleChild
                className="rc"
                blocks={errorMsg}
                serializers={serializers}
              />
            </Alert>
          </motion.div>
        )}
      </AnimatePresence>
    </form>
  )
}

export default Newsletter
