import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import {
  SanityFooterBlock1,
  SanityFooterBlock2,
  SanityFooterBlock3,
} from '@data/sanity/queries/types/site'
import { serializers } from '@lib/serializers'

import Newsletter from '@blocks/newsletter'
import Menu from '@blocks/navigation/menu'

interface FooterBlockProps {
  block: SanityFooterBlock1 | SanityFooterBlock2 | SanityFooterBlock3
}

const FooterBlock = ({ block }: FooterBlockProps) => {
  return (
    <div
      className={cx('flex flex-col p-8 text-left', {
        'lg:order-first': 'newsletter' in block,
        'lg:order-last': 'social' in block,
      })}
    >
      {'content' in block && block.content && (
        <BlockContent
          renderContainerOnSingleChild
          className="rc mb-5"
          blocks={block.content}
          serializers={serializers}
        />
      )}

      {'menu' in block && block.menu?.items.length > 0 && (
        <Menu items={block.menu.items} isFooterMenu />
      )}

      {'newsletter' in block && (
        <Newsletter
          className="max-w-[370px]"
          id={block.newsletter._key}
          klaviyoListID={block.newsletter.klaviyoListID}
          submit={block.newsletter.submit}
          successMsg={block.newsletter.successMsg}
          errorMsg={block.newsletter.errorMsg}
          terms={block.newsletter.terms}
          isInvertedColors
        />
      )}
    </div>
  )
}

export default FooterBlock
