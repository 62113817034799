import { SanityFooterSocialLink } from '@data/sanity/queries/types/site'

interface FooterSocialProps {
  links: SanityFooterSocialLink[]
}

const FooterSocial = ({ links }: FooterSocialProps) => (
  <div className="flex flex-wrap mb-4 mt-2">
    {links.map((link) => (
      <a
        id={`footer-social-link-${link.socialNetwork.toLowerCase()}`}
        className="mr-5 last:mr-0"
        key={link._key}
        href={link.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {link.socialNetwork}
      </a>
    ))}
  </div>
)

export default FooterSocial
