import { SanityFooterFragment } from '@data/sanity/queries/types/site'

import FooterBlock from './block'
import FooterSocial from './social'
import FooterPaymentMethods from './payment-methods'

type FooterProps = SanityFooterFragment

const Footer = ({ blocks, social, copyright, paymentMethods }: FooterProps) => (
  <footer
    className="relative overflow-hidden bg-gray-darkest text-white pt-6 pb-10"
    role="contentinfo"
  >
    <div className="container">
      <div className="grid sm:grid-cols-2 lg:grid-cols-3">
        {blocks?.map((block, index) => (
          <FooterBlock key={index} block={block} />
        ))}
      </div>

      <div className="flex justify-between md:items-center flex-col md:flex-row px-8">
        <div className="mb-2 md:mb-0">
          <FooterSocial links={social ?? []} />
        </div>

        <p className="md:text-center text-sm mb-4 md:mb-0">{copyright}</p>

        <div className="">
          <FooterPaymentMethods paymentMethods={paymentMethods ?? []} />
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
