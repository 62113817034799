import { useContext, useEffect } from 'react'
import Head from 'next/head'

import { SanityAnyPage } from '@data/sanity/queries/types/page'
import { SanitySiteFragment } from '@data/sanity/queries/types/site'
import { getSanityImageUrl } from '@lib/image'
import { MetadataContext } from '@lib/metadata'
import { ShopContext } from '@lib/shop'

interface HeadSeoProps {
  site: SanitySiteFragment
  page: SanityAnyPage
  canonicalUrl?: string
  productSchema?: string
}

const HeadSeo = ({ site, page, canonicalUrl, productSchema }: HeadSeoProps) => {
  const { shopifyDomain } = useContext(ShopContext)
  const { metadata, setMetadata, metadataOverride } =
    useContext(MetadataContext)

  useEffect(() => {
    setMetadata({
      metaTitle: page.seo?.metaTitle ?? site.seo?.metaTitle,
      metaDescription: page.seo?.metaDesc ?? site.seo?.metaDesc,
      shareTitle: page.seo?.shareTitle ?? site.seo?.shareTitle,
      shareDescription: page.seo?.shareDesc ?? site.seo?.shareDesc,
      shareGraphicUrl: getSanityImageUrl(
        page.seo?.shareGraphic ?? site.seo?.shareGraphic,
        { width: 1200, height: 630 }
      ),
    })
  }, [page.seo, site.seo, setMetadata])

  const hasMetadataOverride = !!metadataOverride.metaTitle
  const metaTitle = hasMetadataOverride
    ? metadataOverride.metaTitle
    : metadata.metaTitle
  const metaDescription = hasMetadataOverride
    ? metadataOverride.metaDescription
    : metadata.metaDescription
  const shareTitle = hasMetadataOverride
    ? metadataOverride.shareTitle
    : metadata.shareTitle
  const shareDescription = hasMetadataOverride
    ? metadataOverride.shareDescription
    : metadata.shareDescription
  const shareGraphicUrl = hasMetadataOverride
    ? metadata.shareGraphicUrl
    : metadata.shareGraphicUrl

  const siteTitle = site.seo?.siteTitle

  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="x-ua-compatible" content="ie=edge" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="format-detection" content="telephone=no" />
      {!!page.noIndex && <meta name="robots" content="noindex" />}

      {site.settings?.facebookDomainVerification && (
        <meta
          name="facebook-domain-verification"
          content={site.settings?.facebookDomainVerification}
        />
      )}

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#171718" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta name="theme-color" content="#ffffff" />

      <link rel="preconnect" href={`https://${shopifyDomain}`} />
      <link
        rel="preconnect"
        href="https://cdn.sanity.io"
        crossOrigin="anonymous"
      />

      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={shareTitle} />
      <meta name="twitter:title" content={shareTitle} />
      <meta property="og:description" content={shareDescription} />
      <meta name="twitter:description" content={shareDescription} />

      {shareGraphicUrl && (
        <>
          <meta property="og:image" content={shareGraphicUrl} />
          <meta name="twitter:image" content={shareGraphicUrl} />
        </>
      )}

      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="og:site_name" content={siteTitle} />

      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}

      {productSchema && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: productSchema }}
        />
      )}
    </Head>
  )
}

export default HeadSeo
