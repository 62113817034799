import cx from 'classnames'
import { motion } from 'framer-motion'
import {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { SanityProductFragment } from '@data/sanity/queries/types/product'
import { SanityPrintShop } from '@data/sanity/queries/types/shop'
import { basicProductTypes } from '@lib/sanity/product'
import { itemAnimation } from '@lib/animate'
import { CartContext, getFeeLineItems } from '@lib/cart'
import { hasObject } from '@lib/helpers'
import { Filter, getFallbackVariant } from '@lib/product'
import { getProductVariant } from '@lib/product-card'
import { getPageUrl, PageType, PageUrlOptions } from '@lib/routes'
import { StringsContext } from '@lib/strings'
import { colorUrlParameterName } from '@lib/url'

import { ButtonColor, ButtonVariant } from '@components/buttons/button'
import ProductAdd from '@blocks/product/product-add'
import ProductGallery from '@blocks/product/product-gallery'
import ProductOption from '@blocks/product/product-option'
import ProductPrice from '@blocks/product/product-price'
import ProductThumbnail from '@blocks/product/product-thumbnail'
import SimpleLink from '@components/simple-link'

interface ProductCardProps {
  product: SanityProductFragment
  hasVisuals?: boolean
  showGallery?: boolean
  showThumbnails?: boolean
  showPrice?: boolean
  showOption?: boolean
  showQuickAdd?: boolean
  activeFilters?: Filter[]
  isInline?: boolean
  isFeatured?: boolean
  className?: string
  printShop?: SanityPrintShop
  onClick?: () => void
}

const ProductCard = forwardRef<HTMLDivElement, ProductCardProps>(
  (
    {
      product,
      hasVisuals,
      showGallery,
      showThumbnails,
      showPrice,
      showOption,
      showQuickAdd,
      activeFilters,
      isInline,
      isFeatured,
      className,
      printShop,
      onClick,
    },
    ref
  ) => {
    const strings = useContext(StringsContext)
    const { addItemsToCart } = useContext(CartContext)

    const [variant, setVariant] = useState(() =>
      getProductVariant(product, activeFilters ?? [])
    )

    useEffect(
      () => setVariant(getProductVariant(product, activeFilters ?? [])),
      [activeFilters, product]
    )

    const fallbackVariant = getFallbackVariant(product)
    const mainPhotos = product.photos.main ?? []
    const listingPhotos = product.photos.listing ?? []
    const hasGallery = showGallery && mainPhotos.length > 0
    const hasThumbnails = showThumbnails && listingPhotos.length > 0
    const hasQuickAdd = showQuickAdd && !!variant?.inStock

    const surfacedOption = useMemo(
      () =>
        product.options.find(
          (option) =>
            typeof product.surfaceOption !== 'undefined' &&
            option.position === parseInt(product.surfaceOption) &&
            option.values.length > 1
        ),
      [product]
    )
    const surfacedOptionValue = useMemo(
      () =>
        surfacedOption
          ? variant?.options?.find(
              (variantOption) => variantOption.name === surfacedOption.name
            )?.value
          : undefined,
      [surfacedOption, variant]
    )

    const productUrl = useMemo(() => {
      const urlOptions: PageUrlOptions = {
        parameters: {},
      }

      // Pass selected color to select it by default in product page
      if (
        surfacedOption?.name === 'Color' &&
        surfacedOptionValue &&
        urlOptions.parameters
      ) {
        urlOptions.parameters[colorUrlParameterName] = surfacedOptionValue
      }

      // Pass selected variant to select all its options by default
      // if (variant && urlOptions.parameters) {
      //   urlOptions.parameters.variant = `${variant.id}`
      // }

      if (!printShop) {
        return getPageUrl(PageType.PRODUCT, product.slug, urlOptions)
      }

      return getPageUrl(
        PageType.SHOP_PRODUCT,
        [printShop.type, printShop.id, product.slug],
        urlOptions
      )
    }, [product, printShop, surfacedOption, surfacedOptionValue])

    const handleSurfacedOptionChange = useCallback(
      (newValue: string) => {
        if (!variant || !surfacedOption) {
          return
        }

        const newOptions = variant.options.map((variantOption) =>
          variantOption.name === surfacedOption.name
            ? {
                ...variantOption,
                value: newValue,
              }
            : variantOption
        )

        // Find variant that matches all new options
        const newVariant = product.variants?.find(({ options }) =>
          options.every((variantOption) => hasObject(newOptions, variantOption))
        )

        if (newVariant) {
          setVariant(newVariant)
        }
      },
      [product.variants, variant, surfacedOption]
    )

    const handleAddToCart = async () => {
      if (!variant) {
        return
      }

      const feeLineItems = getFeeLineItems(variant, 1)

      await addItemsToCart(async () => [
        {
          id: variant.id,
          quantity: 1,
        },
        ...feeLineItems,
      ])
    }

    return (
      <motion.div
        ref={ref}
        variants={itemAnimation}
        className={cx(
          'flex flex-col relative group',
          {
            'my-4': isInline,
          },
          className
        )}
      >
        {hasVisuals && variant && (hasGallery || hasThumbnails) && (
          <div className="relative">
            {hasGallery && (
              <div className="relative">
                <ProductGallery
                  photosets={mainPhotos}
                  variant={variant ?? fallbackVariant}
                  hasArrows
                  hasDots
                  hasDrag={false}
                />
              </div>
            )}

            {hasThumbnails && (
              <div className="z-0">
                <ProductThumbnail
                  thumbnails={listingPhotos}
                  variant={variant}
                />
              </div>
            )}

            {hasQuickAdd && basicProductTypes.includes(product.type) && (
              <div
                className={cx(
                  'absolute bottom-0 inset-x-0 z-30 text-center mx-4 mb-8 opacity-0 invisible translate-y-1/2 transition-all will-change-transform',
                  'group-hover:opacity-100 group-hover:visible group-hover:translate-y-0'
                )}
              >
                <ProductAdd
                  variant={ButtonVariant.PRIMARY}
                  color={ButtonColor.WHITE}
                  onClick={handleAddToCart}
                >
                  {strings.buttonAddToCart}
                </ProductAdd>
              </div>
            )}
          </div>
        )}

        <div className="text-left mt-4">
          <div>
            <h4
              className={cx('!m-0 font-sans font-semibold', {
                'text-base': isFeatured,
              })}
            >
              <SimpleLink
                className="block no-underline text-current after:block after:absolute after:inset-0 after:z-20"
                href={productUrl}
                onClick={onClick}
                onBeforeInput={onClick}
                tabIndex={0}
              >
                {product.title}
              </SimpleLink>
            </h4>

            {showPrice && (
              <ProductPrice
                className="flex items-center mt-2"
                price={variant ? variant.price : product.price}
                showFromLabel
                comparePrice={variant?.comparePrice ?? product.comparePrice}
                inProductCard
              />
            )}
          </div>

          {showOption && variant && surfacedOption && (
            <div className="relative z-30 mt-4">
              <ProductOption
                key={surfacedOption.name}
                option={surfacedOption}
                optionSettings={product.optionSettings ?? []}
                product={product}
                value={surfacedOptionValue ?? ''}
                onChange={handleSurfacedOptionChange}
                strictMatch={false}
                hideLabels
                inProductCard
              />
            </div>
          )}
        </div>
      </motion.div>
    )
  }
)

ProductCard.displayName = 'ProductCard'

export default ProductCard
