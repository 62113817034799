import { SanityImageFragment } from '@data/sanity/queries/types/image'

import Photo from '@components/photo'

interface FooterPaymentMethodsProps {
  paymentMethods: SanityImageFragment[]
}

const FooterPaymentMethods = ({
  paymentMethods,
}: FooterPaymentMethodsProps) => (
  <div className="flex flex-wrap mb-4 mt-2">
    {paymentMethods.map((paymentMethod) => (
      <div
        key={paymentMethod.id}
        className="relative block mr-2 last-of-type:mr-0"
      >
        <Photo image={paymentMethod} hasPlaceholder={false} />
      </div>
    ))}
  </div>
)

export default FooterPaymentMethods
