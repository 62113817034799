import { ReactNode, useContext, useState } from 'react'
import { motion, AnimatePresence, Variants } from 'framer-motion'
import { useKeenSlider } from 'keen-slider/react'
import cx from 'classnames'

import { StringsContext } from '@lib/strings'

import Icon from '@components/icon'

interface PhotoCarouselProps {
  id: string
  slideCount: number
  hasArrows?: boolean
  hasDots?: boolean
  hasCounter?: boolean
  hasDrag?: boolean
  cornerControls?: boolean
  className?: string
  children: ReactNode
}

const flipAnimation: Variants = {
  show: {
    y: ['100%', '0%'],
    transition: {
      duration: 1,
      ease: [0.16, 1, 0.3, 1],
      when: 'beforeChildren',
    },
  },
  hide: {
    y: ['0%', '-100%'],
    transition: {
      duration: 1,
      ease: [0.16, 1, 0.3, 1],
      when: 'afterChildren',
    },
  },
}

const PhotoCarousel = ({
  id,
  slideCount,
  hasArrows,
  hasDots,
  hasCounter,
  hasDrag = true,
  cornerControls,
  className,
  children,
}: PhotoCarouselProps) => {
  const strings = useContext(StringsContext)

  const indices = [...Array(slideCount).keys()]

  const [currentSlide, setCurrentSlide] = useState(0)
  const [sliderRef, sliderInstanceRef] = useKeenSlider<HTMLDivElement>({
    selector: '.carousel-slide',
    slides: slideCount,
    loop: true,
    defaultAnimation: {
      duration: 800,
    },
    dragSpeed: 0.8,
    drag: hasDrag,
    slideChanged: (slider) => {
      const newIndex = slider.track.details.rel
      setCurrentSlide(newIndex)
    },
  })

  return (
    <div className={cx('relative w-full bg-transparent', className)}>
      <div
        ref={sliderRef}
        className={cx(
          'keen-slider',
          'flex relative overflow-hidden will-change-transform touch-action touch-action-pan-y items-center',
          {
            'cursor-grab active:cursor-grabbing': hasDrag,
          }
        )}
      >
        {children}
      </div>

      {slideCount > 1 && (
        <div
          className={cx(
            'flex absolute bottom-0 inset-x-0 transform translate-y-1/2 pointer-events-none',
            {
              'justify-center translate-y-1/2': !cornerControls,
              'justify-start translate-y-0': cornerControls,
            }
          )}
        >
          <div
            className={cx(
              'flex flex-row items-center rounded-full p-1 pointer-events-auto',
              {
                'relative bg-black border': !cornerControls,
                'static bg-transparent border-none': cornerControls,
              }
            )}
          >
            {hasArrows && (
              <button
                onClick={() => sliderInstanceRef.current?.prev()}
                aria-label={strings.carouselLeftArrowLabel}
                className={cx(
                  'w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300 hover:bg-white hover:bg-opacity-20',
                  {
                    'absolute bottom-3 right-11': cornerControls,
                  }
                )}
              >
                <Icon name="ArrowLeft" id={`prev-${id}`} className="block" />
              </button>
            )}

            <div
              className={cx(
                'flex items-center justify-center relative h-8 mx-2',
                {
                  'mx-0 pl-1 pb-3': cornerControls,
                }
              )}
            >
              {hasDots && (
                <div
                  className={cx('relative flex items-center h-8', {
                    'ml-3': cornerControls,
                  })}
                >
                  {indices.map((index) => (
                    <button
                      key={index}
                      onClick={() =>
                        sliderInstanceRef.current?.moveToIdx(index)
                      }
                      aria-label={strings.carouselDotLabel.replace(
                        /{index}/gi,
                        (index + 1).toString()
                      )}
                      className={cx(
                        'p-1 bg-transparent',
                        'before:block before:relative before:w-2 before:h-2 before:bg-white before:rounded-full before:opacity-30',
                        'before:transition-carousel-dots',
                        'hover:before:opacity-100',
                        {
                          'before:opacity-100 transform scale-150':
                            currentSlide === index,
                        }
                      )}
                    />
                  ))}
                </div>
              )}

              {hasCounter && (
                <div
                  className={cx('relative grid gap-px rounded-full', {
                    'grid-cols-2 h-8 bg-white text-black': !cornerControls,
                    'grid-cols-3 h-auto bg-transparent text-black select-none':
                      cornerControls,
                  })}
                >
                  {!cornerControls && (
                    <div className="absolute left-1/2 transform -translate-x-1/2 inset-y-3 w-px bg-black opacity-30" />
                  )}
                  <div
                    className={cx('relative flex flex-col p-2 pl-3', {
                      'text-xs font-semibold overflow-hidden': !cornerControls,
                      'px-0 text-base font-normal text-center overflow-visible':
                        cornerControls,
                    })}
                  >
                    <div className="relative overflow-hidden">
                      {/*
                      Note: when using framer-motion version 9.0.3 or above this sometimes shows 2 numbers on top of each other when moving swiftly.
                      Downgrading framer-motion to version 9.0.2 until this bug is fixed: https://github.com/framer/motion/issues/2023
                      */}
                      <AnimatePresence initial={false}>
                        <motion.span
                          key={currentSlide + 1}
                          initial="hide"
                          animate="show"
                          exit="hide"
                          variants={flipAnimation}
                          className="block will-change-transform absolute inset-x-0 top-0 last:static last:inset-x-auto last:top-auto"
                        >
                          {currentSlide + 1}
                        </motion.span>
                      </AnimatePresence>
                    </div>
                  </div>

                  {cornerControls && (
                    <div className="relative flex flex-col p-2 px-0 text-base font-normal text-center overflow-visible">
                      <span>{strings.carouselCounterText}</span>
                    </div>
                  )}

                  <div
                    className={cx('relative flex flex-col p-2 pr-3', {
                      'text-xs font-semibold overflow-hidden': !cornerControls,
                      'px-0 text-base font-normal text-center overflow-visible':
                        cornerControls,
                    })}
                  >
                    <span>{slideCount}</span>
                  </div>
                </div>
              )}
            </div>

            {hasArrows && (
              <button
                onClick={() => sliderInstanceRef.current?.next()}
                aria-label={strings.carouselRightArrowLabel}
                className={cx(
                  'w-8 h-8 p-2 rounded-full bg-transparent transition-colors duration-300 hover:bg-white hover:bg-opacity-20',
                  {
                    'absolute bottom-3 right-3': cornerControls,
                  }
                )}
              >
                <Icon name="ArrowRight" id={`next-${id}`} className="block" />
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default PhotoCarousel
