import { memo } from 'react'
import { useRouter } from 'next/router'

import { SanityPromo } from '@data/sanity/queries/types/site'

import CustomLink from '@components/link'

type PromoBarProps = SanityPromo

const PromoBar = memo(({ enabled, text, link, display }: PromoBarProps) => {
  const router = useRouter()

  const type = display?.trim()

  // Check that promo bar is enabled, type and text are given and the page matches, if type is homepage
  if (
    !enabled ||
    !type ||
    !text ||
    (type === 'home' && router.asPath !== '/')
  ) {
    return null
  }

  return (
    <div className="relative z-60 bg-yellow text-black text-center whitespace-nowrap overflow-x-auto overflow-y-hidden">
      <div className="inline-flex px-8 py-2 text-base font-semibold">
        {link && (
          <CustomLink link={{ page: link }} className="block">
            {text}
          </CustomLink>
        )}
        {!link && <>{text}</>}
      </div>
    </div>
  )
})

PromoBar.displayName = 'PromoBar'

export default PromoBar
